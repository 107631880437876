
import {setCurrentPageBreadcrumbs} from "@/core/helpers/breadcrumb";
import {defineComponent, onMounted} from "vue";
import {setCurrentPageButtons} from "@/core/helpers/page-buttons";
import ReportsComponent from './ReportsComponent.vue';

export default defineComponent({
	name: 'reports',
	components: {
		ReportsComponent
	},
	setup() {
		onMounted(() => {
			setCurrentPageBreadcrumbs('Reports', []);
			setCurrentPageButtons(null, []);
		});
	},
});
