<template lang="pug">
th
	.th-content(@click="toggle")
		.th-inner
			slot
		i.fas(:class="icon")
</template>
<script>
export default {
	name: 'SortColumn',
	props: {
		field: String,
		sorter: Object
	},
	computed: {
		icon(){
			let s = 'fa-sort-' + ((this.sorter.field === this.field && this.sorter.direction === 'asc') ? 'up' :
				(this.sorter.field === this.field && this.sorter.direction === 'desc' ? 'down' : ''));
			return s;
		}
	},
	methods: {
		toggle(){
			let sorter = {
				field: this.sorter.field,
				direction: this.sorter.direction
			};
			if (sorter.field === this.field) {
				sorter.direction = sorter.direction === 'asc' ? 'desc' : 'asc';
			}
			sorter.field = this.field;
			this.$emit('update:sorter', sorter);
			this.$emit('sort');
		}
	}
}
</script>