
import {defineComponent} from 'vue';
import moment from 'moment';
import DataService from '@/core/services/DataService';
import NextDateRangePicker from '@/components/datetime/NextDateRangePicker.vue';
import FiltersStatistics from './FiltersStatistics.vue';
import Entity from '@/components/common/Entity.vue';
import DwLoader from '@/components/DwLoader.vue';
import SortColumn from '@/components/SortColumn.vue';
import DateRangePreset from '@/core/utils/DateRangePreset';

export default defineComponent({
    name: 'ReportsComponent',
    components: {
        Entity, DwLoader, SortColumn,
        NextDateRangePicker, FiltersStatistics
    },
    data(){
        return {
            busy: false,
            dimensions: [],
            metrics: [],
            metricsHash: {},
            sortBy: {
                field: 'revenue',
                direction: 'desc',
            },
            filters: {
                dates: {
                    timezone: 0,
                    dateRange: null
                },
                group_timeframe: '',
                offer_id: [],
                advertiser_id: [],
                publisher_id: [],
                placement_id: [],
                asset_id: [],
                country: [],
                os_id: '',
                attribution_type: '',
                mmp_id: [],
                mmp_account_id: [],
                shortcut_chosen: null,
                account_manager_id: [],
                converted_type: null,
                label_id: null
            },
            modals: {
                savePreset: {show: false}
            },
            pagination: {
                page: 1,
                page_size: 50,
                total: 0
            },
            presets: [],
            records: [],
            summary: false,
            metaData: {
                dimensions: [],
                metrics: []
            },
            notes_map: {}
        };
    },
    computed: {
        selectedDimensions(){
            return this.dimensions.filter(d => d._selected);
        },
        selectedMetrics(){
            return this.metrics.filter(m => m._selected).map((item) => item.name);
        },
        presetSaveModalData() {
            return {
                metrics: this.selectedMetrics,
                dimensions: this.selectedDimensions.map((item) => item.name),
                group_timeframe: this.filters.group_timeframe,
                time_range: this.filters.shortcut_chosen,
                type: 'full'
            }
        },
        preparedPayload() {
            let selectedMetrics: string[] = [];
            this.metrics.forEach(m => {
                if (m._selected) {
                    selectedMetrics.push(m.name);
                }
                if (m.children) {
                    m.children.forEach(cm => {
                        if (cm._selected) {
                            selectedMetrics.push(cm.name);
                        }
                    });
                }
            });
            if (!selectedMetrics.length){
                return;
            }
        
            let data = {
                dimensions: this.dimensions.filter(d => d._selected).map(d => d.name),
                metrics: selectedMetrics,
                timezone: this.filters.dates.timezone,
                date_start: moment(this.filters.dates.dateRange[0]).format('YYYY-MM-DD HH:mm'),
                date_end: moment(this.filters.dates.dateRange[1]).format('YYYY-MM-DD HH:mm'),
                group_timeframe: this.filters.group_timeframe,
                filters: this.filters
            };
            return data;
        }
    },
    methods: {
        clearAll(){
            this.filters.advertiser_id = [];
            this.filters.offer_id = [];
            this.filters.asset_id = [];
            this.filters.placement_id = [];
            this.filters.publisher_id = [];
            this.filters.country = [];
            this.filters.mmp_id = [];
            this.filters.mmp_account_id = [];
            this.filters.account_manager_id = [];
            this.filters.group_timeframe = '';
            this.filters.platform = '';
            this.filters.attribution_type = '';
            this.filters.converted_type = null;
            this.filters.dates.dateRange[0] = moment(moment().format('DD/MM/YYYY') + ' 00:00', 'DD/MM/YYYY HH:mm').toDate();
            this.filters.dates.dateRange[1] = moment(moment().format('DD/MM/YYYY') + ' 23:59', 'DD/MM/YYYY HH:mm').toDate();
        },
        getRowClass(r){
            if (r.placement){
                if(r.note_triggered) {
                    return 'note-triggered';
                }
                if (r.placement.status != 'live'){
                    return 'row-paused';
                } else if (r.placement.effective_status === 'active') {
                    return 'row-active';
                } else {
                    if (r.placement.effective_status_reason === 'conversion cap') {
                        return 'row-cap';
                    }
                    if (r.placement.effective_status_reason === 'impression/click cap') {
                        return 'row-cap';
                    }
                    if (r.placement.effective_status_reason === 'timeframe') {
                        return 'row-timeframe';
                    }
                }

            }
            if (r.offer) {
                if (r.offer.status != 'live'){
                    return 'row-paused';
                } else if (r.offer.effective_status === 'active') {
                    return 'row-active';
                } else {
                    if (r.offer.effective_status_reason === 'conversion cap') {
                        return 'row-cap';
                    }
                    if (r.offer.effective_status_reason === 'impression/click cap') {
                        return 'row-cap';
                    }
                    if (r.offer.effective_status_reason === 'timeframe') {
                        return 'row-timeframe';
                    }
                    return 'row-inactive';
                }
            }
        },
        getColumnClass(r, m) {
            const trackFor = ['passed_clicks', 'passed_impressions'];
            try {
                if(!trackFor.includes(m.name)) return '';
                if(m.name.includes('clicks')) {
                    const placementCC = r.placement.daily_click_cap;
                    const offerCC = r.offer.daily_click_cap;
                    const currentClicks = r.passed_clicks.v;
                    if(placementCC > 0) {
                        return currentClicks >= placementCC ? 'col-cap' : '';
                    }
                    if(offerCC > 0) {
                        return currentClicks >= offerCC ? 'col-cap' : '';
                    }
                }
                if(m.name.includes('impressions')) {
                    const placementIC = r.placement.daily_impression_cap;
                    const offerIC = r.offer.daily_impression_cap;
                    const currentImpressions = r.passed_impressions.v;
                    if(placementIC > 0) {
                        return currentImpressions >= placementIC ? 'col-cap' : '';
                    }
                    if(offerIC > 0) {
                        return currentImpressions >= offerIC ? 'col-cap' : '';
                    }
                } 

            } catch(e) {
                //
            }
            return '';
        },
        toggler(entity) {
            const valueToSet = entity.children.some((item) => !item._selected);
            entity.children = entity.children.map((ch) => {
                ch._selected = valueToSet;
                return ch;
            });
            entity._toggled = valueToSet;
        },
        entityClicked(v: {type: string, id: number}): void {
            if (['asset', 'advertiser', 'publisher', 'offer', 'placement'].indexOf(v.type) === -1){
                return;
            }
            const filtersFieldName = v.type + '_id';
            const filtersFieldValue: number[] = this.filters[filtersFieldName];
            filtersFieldValue.push(v.id);
            const uniqueSet = new Set(filtersFieldValue);
            this.filters[filtersFieldName] = Array.from(uniqueSet);
        },
        async init(){
            this.busy = true;
            let resp = await DataService.get('reports/getInfo');
            this.dimensions = resp.dimensions;
            resp.dimensions.forEach(d => {
                d._selected = false;
            });
            this.metrics = resp.metrics;
            resp.metrics.forEach(d => {
                d._selected = false;
                switch (d.name){
                    case 'conversions': {
                        d.link = {
                            route: 'events',
                            params: {is_conversion: 'only'}
                        };
                        break;
                    }
                    case 'installs': {
                        d.link = {
                            route: 'events',
                            params: {is_install: 'only'}
                        };
                        break;
                    }
                    case 'events': {
                        d.link = {
                            route: 'events'
                        };
                        break;
                    }
                    case 'reject_events': {
                        d.link = {
                            route: 'rejections'
                        }
                        break;
                    }
                }
                this.metricsHash[d.name] = d;
            });
            console.log('resp.presets: ', resp.presets);
            
            this.presets = resp.presets ?? [];
            console.log('this.presets: ', this.presets);
            this.busy = false;
            if (this.presets.length) {
                this.setPreset(this.presets[0], false);
            }
            setTimeout(() => {
                this.loadData();
            });
        },
        
        setPreset(preset: any, loadData = false){
            console.log('preset:', preset);
            this.dimensions.forEach(d => {
                let selected = preset.dimensions && preset.dimensions.indexOf(d.name) > -1;
                this.mutatePresetState(preset, d, selected);

                if (d.children) {
                    d.children.forEach(cd => {
                        let selected = preset.dimensions.indexOf(cd.name) > -1;
                        this.mutatePresetState(preset, cd, selected);
                    });
                }
            });
            if (preset.metrics && preset.metrics.length){
                this.metrics.forEach(d => {
                    let selected = preset.metrics.indexOf(d.name) > -1;
                    this.mutatePresetState(preset, d, selected);

                    if (d.children) {
                        d.children.forEach(cd => {
                            let selected = preset.metrics.indexOf(cd.name) > -1;
                            this.mutatePresetState(preset, cd, selected);
                        });
                    }
                });
            }
            if (preset.group_timeframe){
                this.filters.group_timeframe = preset.group_timeframe;
            } else if (preset.type === 'full'){
                this.filters.group_timeframe = '';
            }
            if (preset.time_range){
                if(typeof preset.time_range === "string") {
                    const timeRangeParsed = (new DateRangePreset()).pickFromType(preset.time_range);
                    if(timeRangeParsed) {
                        this.filters.dates.dateRange = timeRangeParsed;
                        // @ts-ignore
                        this.filters.dates = Object.assign({}, this.filters.dates);
                    }
                } else {
                    if (preset.time_range.start) {
                        this.filters.dates.dateRange[0] = moment(preset.time_range.start, 'YYYY-MM-DD HH:mm:ss').toDate();
                    }
                    if (preset.time_range.end) {
                        this.filters.dates.dateRange[1] = moment(preset.time_range.end, 'YYYY-MM-DD HH:mm:ss').toDate();
                    }
                }
                console.log('set timeframe');
                // @TODO
            }
            if (loadData){
                this.loadData();
            }
        },

        mutatePresetState(preset, targetEntity, selected) {
            if(preset.action === 'set') {
                targetEntity._selected = selected;	
            } else if(preset.action === 'add' && selected) {
                targetEntity._selected = true;	
            } else if(preset.type === 'full') {
                targetEntity._selected = false;
            }
        },


        getQueryParams( params: any, record = false) {
            let data: any = {
                timezone: this.filters.dates.timezone,
                date_start: moment(this.filters.dates.dateRange[0]).format('YYYY-MM-DD HH:mm'),
                date_end: moment(this.filters.dates.dateRange[1]).format('YYYY-MM-DD HH:mm'),
                // date_start: moment().subtract(6, 'days').format('YYYY-MM-DD') + ' 00:00:00',
                // date_end: moment().format('YYYY-MM-DD') + ' 23:59:59',
                group_timeframe: this.filters.group_timeframe,

                asset_id: this.filters.asset_id.map(r => r.v).join(','),
                advertiser_id: this.filters.advertiser_id.join(','),
                publisher_id: this.filters.publisher_id.join(','),
                offer_id: this.filters.offer_id.join(','),
                placement_id: this.filters.placement_id.join(','),
                converted_type: this.filters.converted_type
            };
            // for (let k in this.filters){
            // 	data[k] = this.filters[k];
            // }
            // data.dates = JSON.stringify(data.dates);

            if (params){
                for (let k in params){
                    data[k] = params[k];
                }
            }

            if (record) {
                this.selectedDimensions.forEach(d => {
                    data[d.field] = record[d.field];
                });
            }

            return data;
        },
        
        async loadData(doExport = false, history = false){
            console.log('Statistics loadData');
            if (this.busy){
                return;
            }
            
            let selectedMetrics: string[] = [];
            this.metrics.forEach(m => {
                if (m._selected) {
                    selectedMetrics.push(m.name);
                }
                if (m.children) {
                    m.children.forEach(cm => {
                        if (cm._selected) {
                            selectedMetrics.push(cm.name);
                        }
                    });
                }
            });
            if (!selectedMetrics.length){
                alert('Please select metrics');
                return;
            }

            let data = this.getQueryParams(false, false);
            data.dimensions = this.dimensions.filter(d => d._selected).map(d => d.name);
            data.metrics = selectedMetrics;
            data.filters = this.filters;
            data.sort_col = this.sortBy.field;
            data.sort_dir = this.sortBy.direction;
            data.page = this.pagination.page;
            data.page_size = this.pagination.page_size;
            if (doExport) {
                data.export = true;
            } else if(!doExport && history) {
                data.filters.history = "statistics";
            }
            
            this.busy = true;
            try {
                let resp = await DataService.post('reports/query', data);
                console.log("RESP:", resp, resp.path);
                if (doExport){
                    console.log("Exporting");
                    window.open(resp.path);
                } else {
                    this.setRecords(resp.records);
                    this.summary = resp.summary;
                    this.metaData = {
                        group_timeframe: resp.group_timeframe,
                        dimensions: resp.dimensions,
                        metrics: resp.metrics,
                    };
                    this.pagination.total = resp.total;

                    console.log('records: ', this.records);
                    
                }
            } catch(e){
                console.error(e);
            }
            this.busy = false;
        },
        setRecords(records) {
            if(this.filters.group_timeframe !== "month") {
                this.records = records;
                return;
            }
            this.records = records.map((record) => {
                record.group_time = this.pickMonth(record.group_time) + ' ' + record.group_time_year;
                return record;
            });
            
        },
        pickMonth(num) {
            const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
            try {
                return months[+num - 1]	
            } catch(e) {
                return num;
            }
        }
    },
    mounted() {
        console.log('Mount1');
        
        this.init();
    },
});
