
import { defineComponent, ref } from 'vue';
import OfferSelector from '@/components/selectors/OfferSelector.vue';
import NextDateRangePicker from '@/components/datetime/NextDateRangePicker.vue';

export default defineComponent({
    components: {
		OfferSelector, NextDateRangePicker,
	},
    props: {
		modelValue: {

		},
        filters: {
            type: Object,
            default: () => ({}),
            required: true
        },
        busy: {
            type: Boolean,
            required: true
        },
    },
    data() {
        return {
            user: null,
            firstLoad: true
        };
    },
    setup(props) {
        const filtersRef = ref(props.filters);
        return { filtersRef }
    },
    watch: {
        '$store.getters.currentUser': {
            handler(v) {
                if(this.user && !v) return;
                this.user = this.$store.getters.currentUser || null;
            }, immediate: true
        },
        user: {
            handler(v, old) {
                if (!v) return;
                if( (new Boolean(Object.keys(this.$route.query).length)).valueOf() ) {
                    if(old !== null && !old?.id) {
                        this.user = null;
                        return;
                    } else if(v?.id === old?.id) {
                        this.user = null
                        return;
                    }
                }

                const presets = v.network_to_preset_map ? v.network_to_preset_map[v.network_id] : null;
                const filters = this.filters;
                filters.advertiser_id = presets?.advertiser_id || [];
                filters.publisher_id = presets?.publisher_id || [];
                this.$emit('update:filters', filters);
            }, deep: true, immediate: true
        }
    },


});
